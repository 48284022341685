<template>
  <r-e-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      show-footer
      @click-submit="handleSubmitDictionary"
      @click-cancel="handleCancel"
  >
    <el-form
        title=""
        ref="formDictionary"
        label-width="110px"
        size="small"
        :model="formDictionary"
        :rules="rules"
    >
      <el-form-item label="字典编号" prop="code">
        <el-input :disabled="addEdit === 'edit'" v-model="formDictionary.code"></el-input>
      </el-form-item>
      <el-form-item label="字典名称" prop="codeName">
        <el-input v-model="formDictionary.codeName"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="comment">
        <el-input v-model="formDictionary.comment"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-input type="number" v-model="formDictionary.state"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="indexSeq">
        <el-input type="number" v-model="formDictionary.indexSeq"></el-input>
      </el-form-item>
      <el-form-item label="父类uuid" prop="uuidParent">
        <el-input disabled v-model="formDictionary.uuidParent"></el-input>
      </el-form-item>
      <el-form-item label="数据值" prop="dataValue">
        <el-input v-model="formDictionary.dataValue"></el-input>
      </el-form-item>
    </el-form>
  </r-e-dialog>
</template>

<script>
import { formDictionaryRules } from "@/views/system-management/dictionary-management/data";
import * as dictionaryManagementApi from "@/api/dictionary";
import { MessageSuccess, MessageWarning } from "@custom/message";

export default {
  name: "layer-add-edit-dictionary",
  data() {
    return {
      addEdit: "add",
      dialogTitle: "新增字典",
      dialogVisible: false,
      formDictionary: {},
      rules: formDictionaryRules()
    };
  },
  components: {  },
  methods: {
    layerAddEditDictionaryInit(addEdit, row) {
      // 页面初始化，父页面传递数据
      this.addEdit = addEdit;
      this.dialogVisible = true;
      this.dialogTitle = addEdit === "add" ? "新增字典" : "编辑字典";

      this.editDictionaryInit(row);
    },
    editDictionaryInit(row) {
      // 编辑页面回显数据
      this.formDictionary = row;
    },
    handleSubmitDictionary() {
      // 表单提交
      this.$refs["formDictionary"].validate((valid) => {
        if (valid) {
          const request = this.addEdit === "add" ? "addDictionaryApi" : "editDictionaryApi";
          let param=[];
          if(this.addEdit === "edit") param.push(this.formDictionary.uuid);
          param.push(this.formDictionary);
          dictionaryManagementApi[request](...param).then(() => {
            MessageSuccess(`${ this.dialogTitle }成功!`);
            this.handleCancel("update");
          });
        } else {
          MessageWarning("请填写必填值", 1000);
          return false;
        }
      });
    },
    handleCancel(closeType = "cancel") {
      // 取消、关闭弹窗
      this.dialogVisible = false;
      this.formDictionary = {};
      this.$refs["formDictionary"].resetFields();
      // 新增、编辑成功，需要刷新数据
      if (closeType === "update") this.$parent.getDictionaryTableData();
    }
  }
};
</script>

<style lang="scss" scoped>
.layer-add-edit-dictionary {
  .gao-de-map {
    width: 100%;
    height: VH(400px);
  }
}
</style>
