import request from '@http';

// 2022/07/20 王江毅 公告列表
export function getNoticeList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/announcement/page',
        params
    })
}

export function noticeAdd(data) {
    return request({
        method: 'POST',
        url: '/api/gzf/announcement/save',
        data
    })
}


export function noticeUpdate(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/announcement/update',
        data
    })
}
// 删除公告管理
export function getDelAnnouncement(uuid) {
    return request({
        method: 'delete',
        url: `/api/gzf/announcement/delete/${uuid}`
    });
}