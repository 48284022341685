<template>
    <section class="notice-management">
        <div class="top-bar bg-white">
            <el-form ref="searchForm" :model="formSearch" inline size="small" :rules="{}">
                <el-form-item>
                    <el-input v-model="formSearch.name" placeholder="请输入内容"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-radio-group v-model="formSearch.status" size="small">
                        <el-radio-button label="0">正常</el-radio-button>
                        <el-radio-button label="1">下架</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                    <el-button type="primary" @click="openDialog('add',null)" icon="el-icon-plus">发布公告</el-button>
                </el-form-item>
            </el-form>
        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getPublishPageTableData" :columns="tableColumn"
                   :query="formSearch" :height="820">
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="openDialog('update',row)">编辑</span>
                        <!-- 2024/02/02 张晓瑜新增删除功能 -->
                        <span class="table-btn" @click="delAnnouncement(row)">删除</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
        <layer-add-update-notice ref="layerAddUpdateNotice" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
    import {tableColumn} from "./data";
    import {getNoticeList,getDelAnnouncement} from "@/api/notice-management";
    import { MessageInfo, MessageError, MessageSuccess } from "@custom/message";
    export default {
        name: "notice-management",
        data() {
            return {
                tableColumn: tableColumn,
                formSearch: {},
            };
        },
        components: { layerAddUpdateNotice:()=>import("./components/layer-add-update-notice") },
        async created() {
        },
        mounted() {
        },
        methods: {
            getPublishPageTableData(params) {
                return getNoticeList(params);
            },
            handleSearch(){
                this.$refs['tableRef'].getTableData();
            },
            handleReset(){
                this.formSearch.name ='';
                this.formSearch.status = '';
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            openDialog(type,data){
                this.$refs["layerAddUpdateNotice"].openDialog(type,data);
            },
            // 删除
            delAnnouncement(params) {
                this.$confirm("此操作将删除该公告信息,是否继续?", "注意", {
                    type: "warning",
                }).then(async () => {
                    let {uuid} = params;
                    const loading = this.$loading({
                        lock: true,
                        text: "Loading",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    getDelAnnouncement(uuid)
                        .then((res) => {
                        MessageSuccess("删除该公告信息成功");
                        loading.close();
                        this.handleSearch();
                        })
                        .catch((err) => {
                        MessageError("删除该公告信息失败");
                        loading.close();
                        });
                    })
                    .catch(() => {});
            },
        }
    }
</script>

<style lang="scss" scoped>
    .notice-management {
        padding: 0 VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(15px);
            padding: VH(15px) VW(15px);
            justify-content: left;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
        /deep/ .el-form-item{
            margin-bottom: 0;
        }
    }
</style>