<template>
    <section class="personnel-management padding_10_15">
        <div class="top-bar bg-white">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="openDialog('add',null)">添加人员</el-button>
            <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索" style="margin: 0 9px 0 7px; width: 250px"
                      size="small"></el-input>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getSysadminUserList" :columns="tableColumn"
                   :query="formSearch" tooltip-effect="light" :height="830" :showPagination="false">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="openDialog('update',row)">编辑</span>
                        <span class="table-btn" @click="assignRoles(row)">设置角色</span>
                        <span class="table-btn" @click="removePersonnel(row)">删除</span>
                        <!--                        <span class="table-btn">分配小区</span>-->

                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <dialog-add-personnel ref="dialogAddPersonnel" @handleSearch="handleSearch"/>
        <dialog-save-user-role ref="dialogSaveUserRole" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
import {tableColumn} from "@/views/system-management/personnel-management/data";
import {getSysadminUserList, userDeleteAPI} from "@/api/personnel-management";
import {MessageSuccess, MessageBoxConfirm, MessageInfo} from "@custom/message";

export default {
    name: "personnel-management",
    data() {
        return {
            tableColumn: tableColumn(this),
            tableData: [],
            formSearch: {},
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    components: {
        dialogAddPersonnel: () => import("@/views/system-management/personnel-management/components/dialog-add-personnel.vue"),
        dialogSaveUserRole: () => import("./components/dialog-save-user-role"),
    },
    mounted() {

    },
    methods: {
        getSysadminUserList(params) {

          // console.log(params)
            return getSysadminUserList(params);//params父组件的传过来请求的参数
        },
        handleSearch() {
            this.$refs["orderTableRef"].getTableData(true);
        },
        handleReset() {
            this.formSearch = {};
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
        openDialog(type, data) {
            this.$refs["dialogAddPersonnel"].openDialog(type, data);
        },
        assignRoles(data) {
            this.$refs["dialogSaveUserRole"].openDialog(data);
        },

        removePersonnel(data) {
            const that = this;
            const {fullname, username} = data;
            if (username === 'admin') {
                MessageInfo(fullname + "不可删除");
                return;
            }
            MessageBoxConfirm(() => {
                const loadingOptions = that.loadingOptions;
                const loading = this.$loading({...loadingOptions});
                userDeleteAPI({username}).then(res => {
                    MessageSuccess("删除成功");
                    that.handleSearch();
                }).finally(() => loading.close());
            }, '是否删除"' + fullname + '"的账号');
        }
    }
};
</script>

<style lang="scss" scoped>
.personnel-management {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            white-space: nowrap;
            text-align: center;
        }
    }
}
</style>

